import React from 'react';
import { Link } from 'react-router-dom';
import blogs from './blogData';

const BlogList = () => {
  return (
    <div className="container mx-auto px-6 py-16 bg-gray-200 my-10 rounded shadow">
      <h1 className="text-4xl font-bold mb-6 text-center border-b-2 border-blue-800 pb-5">Blog Posts</h1>
      <ul className="space-y-4 px-10">
        {blogs.map((blog) => (
          <li key={blog.id} className="text-xl font-semibold text-blue-700 hover:underline list-disc">
            <Link to={`/blog/${blog.id}`}>{blog.title}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default BlogList;
