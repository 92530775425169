import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Footer from './components/Footer';
import About from './components/About';
import Apply from './components/Apply';
import FAQ from './components/FAQ';
import Placement from './components/Placement';
import Courses from './components/Courses';
import Contact from './components/Contact';
import CourseDetails from './components/CourseDetails';
import BlogPage from './components/BlogPage';
import BlogList from './components/BlogList';
import BlogEditor from './components/BlogEditor';

function App() {
  return (
    <HelmetProvider>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/top-rated-center-for-maritime-education-and-training-in-india" element={<About />} />
          <Route path="/fill-enrollment-form-and-join-the-best-merchant-navy-institute-in-india" element={<Apply />} />
          <Route path="/explore-faq-and-learn-about-merchant-navy-with-best-merchant-navy-institute-in-india" element={<FAQ />} />
          <Route path="/best-placement-opportunities-at-top-maritime-training-institute-in-india" element={<Placement />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/courses/:courseSlug" element={<CourseDetails />} />
          <Route path="/contact-us-the-best-merchant-navy-institute" element={<Contact />} />
          <Route path="/blog" element={<BlogList />} />
          <Route path="/blog/editor-admin" element={<BlogEditor />} />
          <Route path="/blog/:blogNumber" element={<BlogPage />} />
          {/* Redirect all undefined routes to home page */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        <Footer />
      </Router>
    </HelmetProvider>
  );
}

export default App;
