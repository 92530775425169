import React from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import blogs from './blogData';

const BlogPage = () => {
  const { blogNumber } = useParams();
  const blog = blogs.find((b) => b.id === parseInt(blogNumber));

  if (!blog) {
    return <div className="text-center text-xl font-bold">Blog not found</div>;
  }

  return (
    <div>
      <Helmet>
        <title>{blog.title}</title>
        <meta name="description" content={blog.title} />
      </Helmet>

      {/* Hero Section */}
      <section className="bg-blue-800 text-white py-16">
        <div className="container mx-auto text-center">
          <h1 className="text-4xl font-bold mb-4">{blog.title}</h1>
        </div>
      </section>

      {/* Blog Content */}
      <div className="container mx-auto px-6 py-16">
        <article
          className="prose lg:prose-xl mx-auto"
          dangerouslySetInnerHTML={{ __html: blog.content }}
        ></article>
      </div>
    </div>
  );
};

export default BlogPage;
