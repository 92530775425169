import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const BlogEditor = ({ addBlog }) => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');

  const handleSubmit = () => {
    const newBlog = {
      title,
      content,
    };
    addBlog(newBlog);
    setTitle('');
    setContent('');
  };

  return (
    <div className="container mx-auto p-6">
      <h2 className="text-2xl font-bold mb-4">Create a New Blog Post</h2>
      <input
        type="text"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        placeholder="Blog Title"
        className="mb-4 p-2 border border-gray-300 w-full"
      />
      <ReactQuill value={content} onChange={setContent} className="mb-4" />
      <button onClick={handleSubmit} className="bg-blue-600 text-white p-2">
        Submit Blog
      </button>
    </div>
  );
};

export default BlogEditor;
